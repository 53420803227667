import { TInputProps } from 'components/Form/types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import cx from 'classnames';
import classes from './attachments.module.scss';
import { ValidationMessage } from 'components/Form/ValidationMessage';
import { Trans, useTranslation } from 'react-i18next';
import { useApi } from 'domain/api';
import { File as FileComponent, FileProps } from 'components/Form/Attachments/components/File';
import { DropzoneOptions, FileRejection, useDropzone } from 'react-dropzone';
import { v4 as genId } from 'uuid';
import { ShowMore } from 'components/ShowMore';

export const Attachments = ({
  label,
  meta,
  input: { value = '', onChange, ...input },
  className,
  required,
  readOnly,
  values: _,
  readonly = false,
  ...props
}: TInputProps & { readonly?: boolean }) => {
  const { t } = useTranslation();
  const [files, setFiles] = useState<Required<FileProps>[]>([]);
  const removeFile = useCallback(
    (id: string) => setFiles((files) => files.filter((v) => v.activitymimeattachmentid !== id)),
    []
  );

  const { request } = useApi();

  useEffect(() => {
    if (!value) {
      request({
        url: 'appointments',
        method: 'post',
        data: {
          scheduledend: '2022-05-11T08:52:06.838Z',
          scheduledstart: '2022-05-11T08:52:06.838Z',
          subject: 'Email attachments',
        },
      }).then((resp) => onChange(resp.headers.location.slice(-37, -1)));
    }
  }, [onChange, request, value]);

  const errors: string | undefined = useMemo(() => (meta.touched ? meta.error || meta.submitError : undefined), [meta]);

  const query = useMemo(
    () => ({
      fetch: {
        _attributes: {
          version: '1.0',
          'output-format': 'xml-platform',
          mapping: 'logical',
          distinct: true,
          returntotalrecordcount: true,
          page: 1,
          'no-lock': true,
        },
        entity: {
          _attributes: {
            name: 'activitymimeattachment',
          },
          attribute: ['filename', 'filesize', 'activitymimeattachmentid'].map((name) => ({ _attributes: { name } })),
          order: {
            _attributes: {
              attribute: 'filename',
              descending: false,
            },
          },
          filter: {
            _attributes: { type: 'and' },
            condition: {
              _attributes: {
                attribute: 'activityid',
                operator: 'eq',
                uitype: 'appointment',
                value,
              },
            },
          },
        },
      },
    }),
    [value]
  );

  useEffect(() => {
    if (value) {
      request<{ value: Required<FileProps>[]; '@odata.count': number }>({ url: 'activitymimeattachments', query }).then(
        (resp) => setFiles(resp.data.value)
      );
    }
  }, [query, request, value]);

  const [sizeError, setSizeError] = useState(false);
  const [toUpload, setToUpload] = useState<Array<{ file: File; id: string }>>([]);

  const availableSize = useMemo(
    () =>
      25 * 1024 * 1024 -
      files.reduce((acc, next) => acc + next.filesize, 0) -
      toUpload.reduce((acc, next) => acc + next.file.size, 0),
    [files, toUpload]
  );

  const onDrop: DropzoneOptions['onDrop'] = useCallback(
    (accepted: File[], rejected: FileRejection[]) => {
      if (rejected.length === 0 && accepted.reduce((acc, next) => acc + next.size, 0) < availableSize) {
        setToUpload((v) => [...accepted.map((file: File) => ({ file, id: genId() })), ...v]);
      } else {
        setSizeError(true);
        setTimeout(() => setSizeError(false), 3000);
      }
    },
    [availableSize]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    minSize: 1,
  });

  return (
    <div className={cx(classes.wrapper, className, { [classes.disabled]: props.disabled })}>
      {!readonly && (
        <>
          <label
            className={cx(classes.label, {
              [classes.required]: required,
              [classes.readOnly]: readOnly,
              [classes.disabled]: !!props.disabled,
            })}
            htmlFor={input.name}
          >
            {label}
          </label>
          <div
            {...getRootProps()}
            className={cx(classes.placeholder, { [classes.over]: isDragActive, [classes.withError]: sizeError })}
          >
            <input {...getInputProps()} />
            <div>
              <Trans>
                Drag & Drop or <span className={classes.link}>Browse</span>
              </Trans>
            </div>
            <div className={classes.description}>
              <Trans>The file should be limited to 25 MB</Trans>
            </div>
            {(sizeError || errors) && (
              <ValidationMessage
                error={sizeError ? t('There are 0 MB files or total size exceeds 25 MB') : errors}
                absolute={true}
              />
            )}
          </div>
        </>
      )}
      {!!value && typeof value === 'string' && (
        <ShowMore maxHeight={240} showOnDescTop key={toUpload.length + files.length}>
          <>
            {toUpload.map(({ file, id }) => (
              <FileComponent
                key={id}
                file={file}
                id={value}
                readonly={readonly}
                onRemoveFile={() => setToUpload((v) => v.filter((f) => f.id !== id))}
              />
            ))}
            {files.map((props) => (
              <FileComponent
                key={`${props.activitymimeattachmentid}`}
                {...props}
                id={value}
                readonly={readonly}
                onRemoveFile={() => removeFile(props.activitymimeattachmentid)}
              />
            ))}
          </>
        </ShowMore>
      )}
    </div>
  );
};
