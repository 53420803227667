import SinglePage from 'components/SinglePage';
import {
  config,
  getFormConfig,
  getDetailsConfig,
  isActive,
  isNotEditable,
  useContactMethodValidation,
  validation,
  getLockMessage,
  isNotRemovable,
  links,
  statusFields,
} from 'schemas/resource';
import InquiryList from 'pages/InquiryList';
import EmailList from 'pages/EmailList';
import { config as inquiryConfig } from 'schemas/inquiry';
import { config as emailConfig } from 'schemas/email';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useCallback, useMemo } from 'react';
import { useSystemUserId } from 'lib/helpers';
import EventList from 'pages/EventList';
import EventsTab from 'pages/Resource/components/EventsTab';
import { useRemoveEventParticipants } from 'pages/Common/hooks';
import { isNotRemovable as invitedRequesterNotRemovable } from 'schemas/requesterEvents';
import { useUpdateAttendanceStatusActions } from 'pages/Event/hooks';
import { Action } from 'components/Actions';
import classes from 'pages/Common/pages.common.module.scss';
import { Loader } from 'components/Loader';
import { ApiFilter } from 'lib';
import { Improver } from 'pages/ResourceList';
import CoverageList from 'pages/CoverageList';
import TemplateList from 'pages/TemplateList';

export const Resource = () => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();

  const userId = useSystemUserId();
  const { validate } = useContactMethodValidation();

  const getCoverageInitialValues = useCallback(
    async () => ({
      ownerid: 'systemuser<|>' + id,
    }),
    [id]
  );

  const getEmailInitialValues = useCallback(async () => {
    return {
      directioncode: true,
      from: 'systemuser<|>' + userId,
      to: 'systemuser<|>' + id,
    };
  }, [id, userId]);

  const { getRemoveParticipantsAction, content: removeConfirmationDialog } = useRemoveEventParticipants(
    'resource',
    'event',
    invitedRequesterNotRemovable
  );

  const { actions: attendanceActions, loading } = useUpdateAttendanceStatusActions('resource', 'event');

  const getEventActions = useCallback(
    (data: Record<string, any>) => (baseActions: Action[]) => [
      ...baseActions.filter((a) => a.name !== 'delete'),
      getRemoveParticipantsAction(data),
      ...attendanceActions,
    ],
    [getRemoveParticipantsAction, attendanceActions]
  );

  const coveringByMeFilters: ApiFilter[] = useMemo(
    () => [{ condition: [{ operator: 'eq', attribute: 'bahai_coveringresourceid', value: id }] }],
    [id]
  );

  const ownerFilters: ApiFilter[] = useMemo(
    () => [{ condition: [{ operator: 'eq', attribute: 'ownerid', value: id }] }],
    [id]
  );

  const eventFilters: ApiFilter[] = useMemo(
    () => [{ condition: [{ operator: 'eq', attribute: 'bahai_systemuserid', value: id }] }],
    [id]
  );

  const emailFilters: ApiFilter[] = useMemo(
    () => [{ condition: [{ operator: 'eq', attribute: 'bahai_primaryrowid', value: id }] }],
    [id]
  );

  return (
    <>
      {removeConfirmationDialog}
      {loading && (
        <div className={classes.loader}>
          <Loader />
        </div>
      )}
      <SinglePage
        entityName="resource"
        displayEdit={true}
        displayRemove={false}
        FormImprover={Improver}
        {...{
          config,
          getFormConfig,
          getDetailsConfig,
          isActive,
          isNotEditable,
          useContactMethodValidation,
          validation,
          validate,
          getLockMessage,
          isNotRemovable,
          links,
          statusFields,
        }}
        additionalTabs={[
          {
            label: t('Inquiries'),
            tab: 'inquiry',
            isDefault: true,
            content: () => (
              <InquiryList hiddenFilters={ownerFilters} isSubgrid={true} config={inquiryConfig} isCreateHidden={true} />
            ),
          },
          {
            label: t('Emails'),
            tab: 'email',
            isDefault: true,
            content: () => (
              <EmailList
                getInitialValues={getEmailInitialValues}
                hiddenFilters={emailFilters}
                isSubgrid={true}
                config={emailConfig}
              />
            ),
          },
          {
            label: t('Templates'),
            tab: 'template',
            isDefault: true,
            content: () => <TemplateList getInitialValues={getCoverageInitialValues} hiddenFilters={ownerFilters} />,
          },
          {
            label: t('Coverage'),
            tab: 'coverage',
            isDefault: true,
            suffix: '-my',
            content: () => (
              <CoverageList
                search="bahai_coveringresourceid"
                getInitialValues={getCoverageInitialValues}
                hiddenFilters={ownerFilters}
                isSubgrid
              />
            ),
          },
          {
            label: t('Covering For'),
            tab: 'coverage',
            isDefault: true,
            suffix: '-for',
            content: () => (
              <CoverageList search="ownerid" hiddenFilters={coveringByMeFilters} isSubgrid isCreateHidden />
            ),
          },
          {
            label: t('Events (Invited To)'),
            tab: 'event',
            suffix: '-invvited',
            content: (data) => (
              <EventsTab
                hiddenFilters={eventFilters}
                isCreateHidden={true}
                isSubgrid={true}
                getActions={getEventActions(data)}
                displayViews={false}
              />
            ),
          },
          {
            label: t('Events (Assigned To)'),
            tab: 'event',
            suffix: '-assigned',
            content: () => <EventList hiddenFilters={ownerFilters} isCreateHidden isSubgrid isRemoveHidden />,
          },
        ]}
      />
    </>
  );
};
