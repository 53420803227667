import { Notice } from 'components/DashBoard/Notice';
import classes from './dashboard.module.scss';
import { SimpleTable, SimpleTableProps } from 'components/SimpleTable';
import { config as inquiryConfig, Keys as InquiryKeys, RequesterFields, links as inquiryLinks } from 'schemas/inquiry';
import { config as reminderConfig, Keys as ReminderKeys } from 'schemas/reminder';
import { config as resourceConfig, links as resourceBaseLinks, Keys as ResourceKeys } from 'schemas/resource';
import { config as coverageConfig, Keys as CoverageKeys } from 'schemas/coverage';
import cx from 'classnames';
import { useSystemUserId } from 'lib/helpers';
import { useResourceFilters } from './hooks';
import { useTranslation } from 'react-i18next';
import { ReactComponent as RefreshIcon } from './images/refresh.svg';
import { ReactComponent as ZoomInIcon } from './images/zoom-in.svg';
import { ReactComponent as ZoomOutIcon } from './images/zoom-out.svg';
import { IconButton } from 'components/IconButton';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useColors } from 'providers/EntityMatcher';
import { Calendar } from 'components/Calendar';
import * as inquiryMetadata from 'config/EntityMetadata/bahai_inquiry';
import { bahai_statuscode as reminderStatus } from 'config/EntityMetadata/bahai_reminder';
import { StatusCode as CoverageStatus } from 'config/EntityMetadata/bahai_coverage';
import { Condition, FieldType, filtersConfig } from 'lib';
import { ScreenContext } from 'providers/ScreenProvider';
import { Fields } from 'config';

export const coverageColumns: Partial<Record<Fields<CoverageKeys>, number>> = {
  bahai_id: 150,
  ownerid: 250,
  bahai_startdatetime: 160,
  bahai_enddatetime: 160,
  bahai_coveragereason: 150,
  bahai_comment: 250,
  statuscode: 100,
  createdon: 160,
};

export const inquiryColumns: Record<Fields<InquiryKeys | RequesterFields>, number> = {
  bahai_id: 150,
  'requester.bahai_name': 250,
  'requester.bahai_statuscode': 150,
  'requester.bahai_statusdetailscode': 180,
  'requester.bahai_inquirertypecode': 190,
  bahai_localityid: 150,
  bahai_clusterid: 150,
  bahai_sourcecode: 100,
  createdon: 160,
};

const reminderColumns: Partial<Record<ReminderKeys, number>> = {
  subject: 150,
  bahai_reminderdatetime: 160,
  bahai_statuscode: 130,
  bahai_inquirerid: 250,
  description: 250,
  regardingobjectid: 250,
  bahai_to: 250,
  createdon: 160,
};

const resourceColumns: Partial<Record<ResourceKeys, number>> = {
  fullname: 250,
  bahai_resourceavailability: 150,
  bahai_clusterid: 150,
  'cluster.bahai_name': 150,
  mobilephone: 150,
  internalemailaddress: 170,
};

const Header = ({
  reload,
  text,
  toggle,
  zoomed,
}: {
  reload: () => void;
  toggle: () => void;
  text?: string;
  zoomed?: boolean;
}) => (
  <div className={classes.header}>
    <div className={classes.text}>{text}</div>
    <div className={classes.controls}>
      <IconButton Icon={RefreshIcon} iconOnly onClick={reload} />
      <IconButton Icon={zoomed ? ZoomOutIcon : ZoomInIcon} iconOnly onClick={toggle} />
    </div>
  </div>
);

const descTopSettings = { inquiry: true } as Record<string, boolean>;

export const DashBoard = () => {
  const userId = useSystemUserId();
  const { t } = useTranslation();
  const { isDescTop } = useContext(ScreenContext);
  const [zoom, setZoom] = useState(() => (isDescTop ? descTopSettings : ({} as Record<string, boolean>)));

  useEffect(() => {
    setZoom(isDescTop ? descTopSettings : {});
  }, [isDescTop]);

  const toggle = useCallback((name: string) => setZoom((v) => ({ ...v, [name]: !v[name] })), []);

  const {
    colors: { subBg },
  } = useColors();

  const { resourceLinks, resourceFilters, filtersLoading } = useResourceFilters();

  const getInquiryProps = useCallback(
    (all = false) =>
      ({
        props: { sorting: [{ name: 'createdon', direction: 'desc' }] },
        links: {
          ...inquiryLinks,
          ...(!all && {
            interaction: {
              from: 'bahai_inquiryid',
              to: 'bahai_inquiryid',
              fields: [],
            },
            email: {
              to: 'bahai_inquiryid',
              from: 'regardingobjectid',
              fields: [],
            },
          }),
        },
        hiddenFilters: [
          {
            condition: [
              { attribute: 'ownerid', operator: 'eq', value: userId },
              {
                attribute: 'bahai_statuscode',
                operator: 'ne',
                value: String(inquiryMetadata.bahai_inquirystatus.Concluded),
              },
              ...(all
                ? []
                : [
                    { attribute: 'bahai_id', entityname: 'interaction', operator: 'null' },
                    { attribute: 'activityid', entityname: 'email', operator: 'null' },
                  ]),
            ],
          },
        ],
      }) as Partial<SimpleTableProps>,
    [userId]
  );

  const inquiryProps = useMemo(() => getInquiryProps(), [getInquiryProps]);
  const allInquiryProps = useMemo(() => getInquiryProps(true), [getInquiryProps]);

  const reminderProps: Partial<SimpleTableProps> = useMemo(
    () => ({
      props: { sorting: [{ name: 'bahai_reminderdatetime', direction: 'asc' }] },
      hiddenFilters: [
        {
          condition: [
            ...(filtersConfig.contains.parse({
              operator: 'contains',
              attribute: 'requiredattendees',
              value: `systemuser<|>${userId}`,
              type: FieldType.PartyList,
            }).condition || ([] as Condition[])),
            { attribute: 'bahai_statuscode', operator: 'ne', value: `${reminderStatus.Completed}` },
          ],
        },
      ],
    }),
    [userId]
  );

  const resourcesProps: Partial<SimpleTableProps> = useMemo(
    () => ({
      props: { sorting: [{ name: 'fullname', direction: 'asc' }] },
      links: {
        ...resourceBaseLinks,
        ...resourceLinks,
      },
      hiddenFilters: resourceFilters,
      ready: !filtersLoading,
    }),
    [filtersLoading, resourceFilters, resourceLinks]
  );

  const coverageProps: Partial<SimpleTableProps> = useMemo(
    () => ({
      props: { sorting: [{ name: 'bahai_startdatetime', direction: 'asc' }] },
      hiddenFilters: [
        {
          condition: [
            { attribute: 'bahai_coveringresourceid', operator: 'eq', value: userId },
            { attribute: 'statuscode', operator: 'ne', value: String(CoverageStatus.Finished) },
          ],
        },
      ],
    }),
    [userId]
  );

  return (
    <div className={classes.root} style={{ backgroundColor: subBg }}>
      <Notice />
      <div className={classes.grid}>
        <div className={cx(classes.block, { [classes.long]: zoom['inquiry'] })}>
          <SimpleTable columns={inquiryColumns} config={inquiryConfig} entityName="inquiry" {...inquiryProps}>
            {({ reload }) => (
              <Header
                reload={reload}
                zoomed={zoom['inquiry']}
                toggle={() => toggle('inquiry')}
                text={t('New Inquiries')}
              />
            )}
          </SimpleTable>
        </div>
        <div className={cx(classes.block, { [classes.long]: zoom['allInquiry'] })}>
          <SimpleTable columns={inquiryColumns} config={inquiryConfig} entityName="inquiry" {...allInquiryProps}>
            {({ reload }) => (
              <Header
                reload={reload}
                zoomed={zoom['allInquiry']}
                toggle={() => toggle('allInquiry')}
                text={t('All Inquiries')}
              />
            )}
          </SimpleTable>
        </div>
        <div className={cx(classes.block, { [classes.long]: zoom['reminder'] })}>
          <SimpleTable columns={reminderColumns} config={reminderConfig} entityName="reminder" {...reminderProps}>
            {({ reload }) => (
              <Header
                reload={reload}
                zoomed={zoom['reminder']}
                toggle={() => toggle('reminder')}
                text={t('Reminders')}
              />
            )}
          </SimpleTable>
        </div>
        <div className={cx(classes.block, { [classes.long]: zoom['coverage'] })}>
          <SimpleTable columns={coverageColumns} config={coverageConfig} entityName="coverage" {...coverageProps}>
            {({ reload }) => (
              <Header
                reload={reload}
                zoomed={zoom['coverage']}
                toggle={() => toggle('coverage')}
                text={t('Covering For')}
              />
            )}
          </SimpleTable>
        </div>
        <div className={cx(classes.block, { [classes.long]: zoom['events'] })}>
          <Calendar fullSize={zoom['events']}>
            {({ reload }) => (
              <Header
                reload={reload}
                zoomed={zoom['events']}
                toggle={() => toggle('events')}
                text={t('Calendar of Events')}
              />
            )}
          </Calendar>
        </div>
        <div className={cx(classes.block, { [classes.long]: zoom['resource'] })}>
          <SimpleTable columns={resourceColumns} config={resourceConfig} entityName="resource" {...resourcesProps}>
            {({ reload }) => (
              <Header
                reload={reload}
                zoomed={zoom['resource']}
                toggle={() => toggle('resource')}
                text={t('Resources')}
              />
            )}
          </SimpleTable>
        </div>
      </div>
    </div>
  );
};
